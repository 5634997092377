.mini-title-animation {
    word-wrap: break-word; /* Allows wrapping only at word boundaries */
    word-break: break-word; /* For legacy support */
    overflow-wrap: break-word; /* Ensures text wraps nicely on small screens */
    white-space: normal; /* Prevents text from sticking to one line */
  }

.CookieConsent{
    background-color: #000000 !important;
    align-items: center !important;
    border-top: 1px solid #61b3ff;
    padding: 0px 40px;
    position: relative;
    z-index: 999999999 !important;
}
#rcc-decline-button{
    display: none;
}
.CookieConsent p{
    margin-bottom: 0px;
    font-size: 15px;
    max-width: 80%;
    font-weight: 500;
    color: #ffffff !important;
}
.CookieConsent span{
    font-size: 15px;
    font-weight: 500;
    color: #ffffff !important;
}
.CookieConsent span a{
    font-size: 15px;
    font-weight: 500;
    color: #61b3ff !important;
}
#rcc-decline-button{
    background-color: #000 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}
#rcc-confirm-button{
    background-color: #027EF0 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #fff !important;
}
a{
    cursor: pointer !important;
}
.error {
    color: rgb(255, 16, 16) !important;
    font-size: 0.875rem;
    padding: 5px 10px;
    font-weight: 500;
  }

/* chatbot css */

.no-scroll {
    overflow: hidden !important;
    height: 100vh !important;
}
/* Ensure the chatbot content scrolls */
.chatbot-container {
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 90vh; /* Ensure it fits the screen height */
    width: 350px; /* Adjust width as needed */
    overflow-y: auto; /* Enable vertical scrolling for content */
    z-index: 999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: white; /* To prevent transparency issues */
}
.chatbot-container .rsc-float-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: rgb(0,133,255);
    background: linear-gradient(41deg, rgba(0,133,255,1) 0%, rgba(0,0,0,1) 100%);
    border: 2px solid #fff;
    transition: all 1s ease;
}
.chatbot-container .rsc-float-button:hover{
    box-shadow: 0 0 100px #027df034, 0 0 60px #027df066, 0 0 40px #027EF0;
}
.AboutSection .content .shape img{
    max-width: 50%;
}
.rsc-ts-bot .rsc-ts-bubble{
    max-width: 60%;
}
 .rsc-os-option-element{
    margin-bottom: 5px;
    padding: 5px 20px !important;
    background: #fff !important;
    border: 1px solid #027EF0 !important;
    color: #027EF0 !important;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.preloader .loader-content {
    position: relative; 
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader .loader-content .logo-icon {
    animation: rotateAndMoveLeft 2s linear forwards;
}

.preloader .loader-content .logo-icon img {
    width: 70px;
}

.preloader .loader-content .logo-name {
    position: absolute; /* Position absolutely within loader-content */
    opacity: 0;
    transform: translateX(50px); /* Start off-screen to the right */
    animation: fadeInAndSlideLeft 1s ease-in-out 1s forwards;
}

.preloader .loader-content .logo-name img {
    width: 200px;
}
/* Rotate twice, then move left */
@keyframes rotateAndMoveLeft {
    0% {
        transform: translateX(0px) rotate(0deg);
    }
    33% {
        transform: translateX(0px) rotate(360deg);
    }
    66% {
        transform: translateX(0px) rotate(720deg);
    }
    100% {
        transform: translateX(-150px) rotate(720deg);
    }
}

/* Fade in and slide the logo name from the right */
@keyframes fadeInAndSlideLeft {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}



/* all page loader  */



.preloaderAll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}
.logo-icon-all{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.logo-icon-all img{
    animation: rotate 1s linear forwards 2;
    transform-origin: center;
}

.logo-icon-all img {
    width: 70px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(720deg);
    }
}



/* main style start */
.sec-padding {
    padding: 100px 0px;
}
form input{
    color: #fff;
}
textarea{
    color: #fff;
}
.banner-section{
    position: relative;
    overflow: hidden;
    height: 900px;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,33,63,1) 64%, rgba(0,63,120,1) 74%, rgba(2,126,240,1) 88%);
}
.banner-ellipse1 {
    position: absolute;
    opacity: 60%;
    top: 750px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 82%, rgba(0, 73, 142, 1) 82%);
    
    filter: blur(150px);
    overflow: hidden;
    height: 3000px;
    width: 3000px;
    border-radius: 50%;
}
.banner-section .banner-logo img{
    width: 600px;
    height: 600px;
    mix-blend-mode: screen;
}

.hidden {
    display: none !important; /* Force the preloader to disappear */
}


/* .banner-section .banner-logo canvas{
    width: 600px;
    height: 600px;
} */
.banner-section .banner-title{
    padding:80px 0px;
}
.banner-section .banner-title h1{
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 110px;
    line-height: 110px;
    color: #fff;
}
.banner-section .banner-title p{
    font-family: var(--inter-font);
    margin-top: 50px;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
}
.grid-shape{
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;
    opacity: 40%;
}
.grid-shape img{
    width: 750px;
    height: 750px;
}
.banner-content-right{
    position: relative;
}
.banner-section .banner-logo{
    z-index: 2;
    position: absolute;
    display: inline-block;
    will-change: transform; /* Optimize for performance */
    perspective: 600px; /* Define perspective for 3D effect */
}
.image-container img {
    max-width: 100%; 
    transition: transform 0.1s ease-out;
}
.banner-mini-shape-1{
    position: absolute;
    top: 0;
    right: 0;
}
.banner-mini-shape-1 img{
    width: 70px;
    opacity: 90%;
}
.banner-mini-shape-2{
    position: absolute;
    top: 550px;
    left: -10%;
    opacity: 90%;
}
.banner-mini-shape-2 img{
    width: 150px;
}
.banner-section .banner-mini-shape-1{
    animation:ease infinite;
    animation-name: movement;
    animation-duration: 4s;
}
.banner-section .banner-mini-shape-2{
    animation:ease infinite;
    animation-name: movement;
    animation-duration: 4s;
}

@keyframes movement {
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(40px);
    }
    100%{
        transform: translateX(0px);
    }
}


/* partners style */
.banner-section .partners-slider{
    overflow: hidden;
    margin-top: 150px;
    z-index: 99;
}
.banner-section .partners-slider .image img{
    width: 60px;
    height: 100%;
    opacity: 80%;
}
.banner-section .partners-slider .servincen img{
    width: 180px;
    text-align: center;
    opacity: 80%;
}
.banner-section .partners-slider .image {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-section .partners-shape {
    position: absolute;
    z-index: 1;
    background: rgb(2, 2, 2);
    background: radial-gradient(circle, rgba(2, 2, 2, 1) 70%, rgba(0, 0, 0, 1) 82%, rgba(0, 75, 144, 1) 82%);
    bottom: -800px;
    right: 50%;
    transform: translateX(50%);
    height: 1000px;
    width: 2500px;
    border-radius: 50%;
    box-shadow: inset 0 0 60px 60px rgba(2, 126, 240, 0.5);
}

.banner-section .partners-shape::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2px solid #61b3ff; 
    box-sizing: border-box;
    pointer-events: none; 
}




.large-title h1 {
    font-family: var(--graphik-font);
    font-size:90px;
    line-height: 100px;
    font-weight: 600;
}
.large-title p{
    text-align: center;
}

/* about section style */

.AboutSection .content{
    position: relative;
}
.left-subtitle{
    position: relative;
}
.left-subtitle h6{
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 30px;
}
.left-subtitle h6::before{
    content: "";
    position: absolute;
    height: 6px;
    left: 0;
    width: 20px;
    border-radius: 20px;
    background-color: #027EF0;
}
.AboutSection .content .large-title h1 {
    color: #81c2ff;
}
.AboutSection .content p{
    margin-top: 20px;
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(118,117,133,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.AboutSection .content .shape{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 120px;
    top: 30%;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: rgb(0,133,255);
    background: linear-gradient(41deg, rgba(0,133,255,1) 0%, rgba(0,0,0,1) 100%);
    border: 2px solid #fff;
    transition: all 1s ease;
}
.AboutSection .content .shape:hover{
    box-shadow: 0 0 100px #027df034, 0 0 60px #027df066, 0 0 40px #027EF0;
}
.AboutSection .content .shape img{
    max-width: 50%;
}
.AboutSection .right-content p{
    color: #FBFBFB;
    font-size: 18px;
    line-height: 30px;
}
.gradient-bg{
   position: relative;
   overflow: hidden;
}

.gradient-bg-radial-1{
    position: absolute;
    top: 20%;
    right: -42%;
    height: 800px;
    width: 700px;
    border-radius: 500px;
    filter: blur(100px);
    background: rgb(0,64,255);
    background: radial-gradient(circle, rgba(0,64,255,1) 0%, rgba(0,64,255,0) 100%);
}
.gradient-bg-radial-2{
    position: absolute;
    top: 20%;
    left: -42%;
    height: 1200px;
    width: 700px;
    border-radius: 500px;
    filter: blur(100px);
    background: rgb(0,64,255);
    background: radial-gradient(circle, rgba(0,64,255,1) 0%, rgba(0,64,255,0) 100%);
}
/* assurance style */

.assurance_section .title-box{
    border-radius: 20px;
    padding: 50px 0px;
    position: relative;
    background: rgb(0,81,155);
    background: linear-gradient(90deg, rgba(0,81,155,1) 0%, rgba(0,0,0,1) 68%, rgba(17,27,61,1) 100%);
}
.assurance_section .title-box .shape .line1{
    position: absolute;
    top: 15%;
    left: 0;
    transform: translateY(15%);
}
.assurance_section .title-box .shape .line2{
    position: absolute;
    top: 20%;
    right: 0;
    transform: translateY(20%);
}
.assurance_section .title-box .sec-title{
    text-align: center;
}
.assurance_section .title-box .sec-title h1{
    color: #fff;
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 50px;
}
.center-subtitle h6{
    color: #fff;
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 15px;
    color: #fff;
    padding: 0px 20px;
}
.center-subtitle{
    position: relative;
    text-align: center;
}
.center-subtitle h6{
    display: inline-block;
    position: relative;
    padding: 0px 5px;
}

.center-subtitle h6::before {
    content: "";
    position: absolute;
    height: 6px;
    left: 0;
    width: 20px;
    border-radius: 20px;
    background-color: #027EF0;
    top: 50%; 
    transform: translateY(-50%);
    margin-left: -30px; 
}

.center-subtitle h6::after {
    content: "";
    position: absolute;
    height: 6px;
    right: 0;
    width: 20px;
    border-radius: 20px;
    background-color: #027EF0;
    top: 50%; 
    transform: translateY(-50%); 
    margin-right: -30px; 
}
.assurance_section .box-content{
    height: 350px;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	width: 100%;
	height: 350px;
	border-radius: 20px;
	overflow: hidden;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	font-weight: bold;
    transition: all 5s ease;
}

/* Add the animated element on hover */
.rainbow:hover::before {
	content: '';
	position: absolute;
	z-index: -2;
	left: -50%;
	top: -50%;
	width: 200%;
	height: 200%;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: 50% 50%, 50% 50%;
	background-position: 0 0, 100% 0, 100% 100%, 0 100%;
	background-image: linear-gradient(#027EF0, #027EF0), linear-gradient(#000000, #000), linear-gradient(#027EF0, #027EF0), linear-gradient(#000, #000);
	animation: rotate 5s linear infinite;
}

/* Inner gradient background layer */
.rainbow::after {
    border: none;
	content: '';
	position: absolute;
	z-index: -1;
	left: 2px;
	top: 2px;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	background: rgb(8,9,11);
	background: linear-gradient(144deg, rgba(8,9,11,1) 35%, rgba(7,30,51,1) 69%, rgba(7,43,79,1) 85%, rgba(4,66,122,1) 100%);
	border-radius: 20px;
}






  
.assurance_section .box-content{
    margin-top: 30px;
}
.assurance_section .box1{
    border-radius: 20px;
    background: rgb(8,9,11);
    background: linear-gradient(144deg, rgba(8,9,11,1) 35%, rgba(7,30,51,1) 69%, rgba(7,43,79,1) 85%, rgba(4,66,122,1) 100%);
}
.assurance_section .box2{
    border-radius: 20px;
    background: rgb(4,43,146);
    background: linear-gradient(43deg, rgba(4,43,146,1) 0%, rgba(4,32,105,1) 16%, rgba(6,21,59,1) 48%, rgba(8,11,14,1) 87%);   
}

.assurance_section .box-content .icon img{
    color: #fff;
}
.assurance_section .box-content .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    border-radius: 10px;
    height: 50px;
    width: 50px;
}
.assurance_section .box-content h1{
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    text-align: center;
}
.assurance_section .box-content p{
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 15px;
    color: #fff;
    text-align: center;
}


/* who we are styles */

.who-we-are-section{
    
    position: relative;
}
.who-we-are-section .left-image{
    position: relative;
}
.who-we-are-section .left-image img{
    width: 380px;
    height: 650px;
    transition: all 1s ease;
}
.who-we-are-section .left-image:hover img{
    transform: scale(1.1);
}
.who-we-are-section .left-image .gradient-shape{
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 25%;
    height: 100px;
    width: 100px;
    filter: blur(10px);
    border-radius: 50%;
    background: rgb(0,157,255);
    background: radial-gradient(circle, rgba(0,157,255,0) 40%, rgba(0,157,255,1) 100%);
}
.who-we-are-section .content h1 {
    font-size: 90px;
    line-height: 100px;
    font-weight: 600;
    color: #81c2ff;
    /* background: rgb(255,255,255);
    background: linear-gradient(260deg, rgba(255,255,255,1) 30%, rgba(0,133,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
    margin-bottom: 30px;
}

.who-we-are-section .content p{
    color: #FBFBFB;
    font-size: 18px;
    line-height: 30px;
}
.who-we-are-section .checked-shape img{
    width: 420px;
    height: 420px;
}
.who-we-are-section .checked-shape{
    position: absolute;
    top: 0;
    left: -15%;
}



/* core service style */
.our-core-services .sec-title{
    margin-bottom: 150px;
    text-align: center;
    position: relative;
}
.our-core-services .sec-title .large-title h1 {
    color: #81c2ff;
}
.our-core-services .sec-title p{
    text-align: center;
    width: 80%;
    margin: auto;
      font-family: var(--inter-font);
    font-weight: 300;
    font-size: 18px;
    color: var(--light-color);
}
.our-core-services .service-box {
    z-index: 99;
    margin-bottom: 100px;
    position: relative;
    border-radius: 20px;
    border: 1px solid #027EF0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 66%);
    height: 380px;
    width: 100%;
    padding: 30px;
    text-align: center;
    transition: all 1s ease !important;
}

.our-core-services .service-box:hover {
    background-color: #000;
    margin-top: -20px;
}

.our-core-services .service-box .icon img {
    transition: transform 1s ease-in-out; 
}

.our-core-services .service-box:hover .icon img {
    transform: rotate(360deg); /* Rotate on hover */
}
.our-core-services .service-box .content{
    padding-top: 50px;
}
.our-core-services .service-box .content h1{
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 25px;
    color: #fff;
    text-align: center;
}
.our-core-services .service-box .content p{
    padding-top: 20px;
      font-family: var(--inter-font);
    font-weight: 300;
    font-size: 15px;
    color: var(--light-color);
}
.our-core-services .service-box .icon{
    position: absolute;
    top: -50px;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: #fff;
}
.our-core-services .service-box .icon img{
   width: 30px;
}
.gradient-bg-2{
    position: relative;
    overflow: hidden;
 }
 
 .gradient-bg-2 .gradient-bg-radial-1{
     position: absolute;
     z-index: 1;
     top: 2%;
     right: -36%;
     height: 800px;
     width: 1000px;
     border-radius: 500px;
     filter: blur(100px);
     background: rgb(0,64,255);
     background: radial-gradient(circle, rgba(0, 64, 255, 0.543) 0%, rgba(0,64,255,0) 100%);
 }
 .gradient-bg-2 .gradient-bg-radial-2{
     position: absolute;
     left: -36%;
     top: 2%;
     height: 800px;
     width: 1000px;
     border-radius: 500px;
     filter: blur(100px);
     background: rgb(0,64,255);
     background: radial-gradient(circle, rgba(0, 64, 255, 0.543) 0%, rgba(0,64,255,0) 100%);
 }
 .gradient-bg-2 .gradient-bg-radial-3{
    position: absolute;
    top: 25%;
    right: 50%;
    transform: translateX(50%) rotate(30deg);
    height: 1200px;
    width: 1000px;
    border-radius: 500px;
    filter: blur(100px);
    background: rgb(0,64,255);
    background: radial-gradient(circle, rgba(0, 64, 255, 0.543) 0%, rgba(0,64,255,0) 100%);
}
.our-core-services .checked-shape img{
    opacity: 40%;
    width: 500px;
    height: 550px;
}
.our-core-services .checked-shape{
    position: absolute;
    bottom: 0%;
    opacity: 0.5;
    right: 0;
}



/* approach style */

.approach-section{
    background-position: center;
    background-image: url("../Resources/approach-image-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
}
.approach-section .sec-title{
    margin-bottom: 80px;
    text-align: center;
}
.approach-section .sec-title .large-title h1 {
    color: #000;
    text-align: center;
}
.sec-title p{
    width: 80%;
    margin: auto;
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 18px;
    color: #3B3B3B;
}

.approach-section .sec-title .center-subtitle h6{
    color: #000;
}
.approach-section .sec-title  p{
    margin-top: 10px;
    text-align: center;
}
.approach-section .box-content .approach-box{
    margin-bottom: 20px;
    z-index: 99;
    position: relative;
    border-radius: 20px;
    border: 1px solid #027EF0;
    background-color: #fff;
    height: 350px;
    width: 100%;
    padding: 30px;
    transition: all 1s ease !important;
}
.approach-section .box-content .approach-box:hover{
    background-color: #000;
}
.approach-section .box-content .approach-box:hover .content h1{
    color: #fff;
}
.approach-section .box-content .approach-box .icon{
    background-color: #027EF0;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.approach-section .box-content .approach-box .content h1{
    font-family: var(--graphik-font);
    font-weight: 600;
    font-size: 25px;
    color: #000;
    margin-bottom: 15px;
}
.approach-section .box-content .approach-box .content p{
    font-family: var(--inter-font);
    font-weight: 200;
    font-size: 15px;
    color: var(--light-color);
}
.approach-section .box-content .approach-box-large{
    display: flex;
    justify-content: space-between;
}
.approach-section .box-content .approach-box-large .image{
    height: 100%;
    width: 100%;
}
.approach-section .box-content .approach-box-large .image img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.approach-section .box-content .approach-box-large .content{
    width: 90%;
}



/* technologies styles */


.technologies-section .sec-title{
    margin: auto;
    width: 50%;
    margin-bottom: 80px;
    text-align: center;
}
.technologies-section .sec-title .large-title h1 {
    font-family: var(--graphik-font);
    font-size: 90px;
    line-height: 100px;
    font-weight: 600;
    color: #81c2ff;
}
.technologies-section .sec-title p{
    justify-content: center;
    margin: auto;
    text-align: center;
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 15px;
    color: var(--light-color);
}
.technologies-section {
    margin: 800px 0px 600px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.technologies-section .shape {
    position: relative;
}

.technologies-section .sec-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; 
}

.technologies-section .circle1,
.technologies-section .circle2,
.technologies-section .circle3 {
    position: absolute;
    border-radius: 50%;
}

.technologies-section .circle1 {
    width: 750px;
    height: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(0deg, rgba(78, 210, 246, 1) 0%, rgba(4, 238, 255, 1) 100%);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    box-shadow: 0 0 30px 15px rgba(78, 210, 246, 0.5); /* Adjusted shadow for visibility */
}

.technologies-section .circle2 {
    opacity: 70%;
    width: 1000px;
    height: 1000px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(0deg, rgba(78, 137, 246, 1) 0%, rgba(4, 109, 255, 1) 100%);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    box-shadow: 0 0 30px 15px rgba(78, 137, 246, 0.5); /* Adjusted shadow for visibility */
}

.technologies-section .circle3 {
    opacity: 40%;
    width: 1250px;
    height: 1250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(0deg, rgba(87, 78, 255, 1) 0%, rgba(87, 78, 255, 1) 100%);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    mask: radial-gradient(farthest-side, transparent calc(100% - 2px), black calc(100% - 2px));
    box-shadow: 0 0 30px 15px rgba(87, 78, 255, 0.5); /* Adjusted shadow for visibility */
}

.technologies-section .content-one{
    position: absolute;
    z-index: 99;
    top: -700px;
    right: 35%;
    display: flex;
    border-radius: 20px;
    background: rgb(0,91,175);
    background: linear-gradient(125deg, rgba(0,91,175,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,91,175,1) 100%);
    height: 200px;
    width: 420px;
    padding: 20px;
    text-align: center;
}
.technologies-section  .box-content{
    border: 1px solid #027EF0;
    transition: all 1s ease !important;
}
.technologies-section  .box-content:hover{
    border: 1px solid #ffff;
    margin-top: -20px;
}
.technologies-section .box-content .icon{
    padding-right: 25px;
}
.technologies-section .box-content .icon img{
    width: 35px;
}
.technologies-section .box-content .content h1{
      font-family:var(--graphik-font);
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    text-align: left;
}
.technologies-section  .box-content .content p{
      font-family: var(--inter-font);
    font-weight: 300;
    font-size: 14px;
    color: var(--light-color);
    text-align: left;
}
.technologies-section .content-two{
    position: absolute;
    z-index: 99;
    top: -400px;
    left: 0;
    display: flex;
    border-radius: 20px;
    background: rgb(0,91,175);
    background: linear-gradient(125deg, rgba(0,91,175,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,91,175,1) 100%);
    height: 200px;
    width: 420px;
    padding: 20px;
    text-align: center;
}
.technologies-section .content-three{
    position: absolute;
    z-index: 99;
    top: -370px;
    right: 0;
    display: flex;
    border-radius: 20px;
    background: rgb(0,91,175);
    background: linear-gradient(125deg, rgba(0,91,175,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,91,175,1) 100%);
    height: 200px;
    width: 420px;
    padding: 20px;
    text-align: center;
}
.technologies-section .content-four{
    position: absolute;
    z-index: 99;
    top: 200px;
    left: 0;
    display: flex;
    border-radius: 20px;
    background: rgb(0,91,175);
    background: linear-gradient(125deg, rgba(0,91,175,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,91,175,1) 100%);
    height: 200px;
    width: 420px;
    padding: 20px;
    text-align: center;
}
.technologies-section .content-five{
    position: absolute;
    z-index: 99;
    top: 280px;
    right: 150px;
    display: flex;
    border-radius: 20px;
    background: rgb(0,91,175);
    background: linear-gradient(125deg, rgba(0,91,175,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,91,175,1) 100%);
    height: 200px;
    width: 420px;
    padding: 20px;
    text-align: center;
}


/* cta section */

.cta-section{
    background-image: url('../Resources/cta-bg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;

}
.cta-section .sec-title{
    padding: 150px 0px 100px 0px;
    margin: auto;
    width: 68%;
    margin-bottom: 80px;
    text-align: center;
}
.cta-section .sec-title .large-title h1 {
    color: #81c2ff;
}
.cta-section .sec-title p{
    margin: auto;
    text-align: center;
    font-family: var(--inter-font);
    font-weight: 300;
    font-size: 18px;
    width: 100%;
    color: var(--light-color);
}
.common-btn button{
    margin-top: 30px;
    background: rgb(2,126,240);
    background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
    height: 60px;
    width: 180px;
    border-radius: 10px;
    border: none;
      font-family: var(--inter-font);
    font-weight: 600;
    font-size: 17px;
    transition: all 1s ease !important;
}
.common-btn button a{
    text-decoration: none;
}
.common-btn button:hover{
    border: 1px solid #027EF0;
    box-shadow: 0 0 100px #027df034, 0 0 60px #027df066, 0 0 40px #027EF0;
}

.video-canvas {
    width: 100%;
    height: auto;
    display: block;
    background-color: transparent; 
}

#technology-sec-mobile{
    display: none;
}

.offcanvas{
    width: 100% !important;
    z-index: 9999999999;
}
.home-certification-new{
    padding: 100px 0px;
}


.home-certification-new .item{
    background-color: #000;
    border: 1px solid #027EF0;
    padding: 20px;
    border-radius: 20px;
    transition: 2s all ease;
}
.home-certification-new .item:hover{

    box-shadow: 0 0 60px #027df034, 0 0 40px #027df066, 0 0 20px #027EF0;
}

.home-certification-new .large-title h1{
    color: #81c2ff;
}
.home-certification-new .large-title{
    text-align: center;
    margin-bottom: 50px;
}
.home-certification-new .item .image{
    margin: auto;
    height: 100px;
    width: 100px;
}
.home-certification-new .item .image svg{
    opacity: 0.5;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.home-certification-new .item .content{
    text-align: center;
    margin-top: 20px;
}
.home-certification-new .item .content h6{
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}
.home-certification-new .item .content p{
    font-size: 15px;
    color: var(--light-color);
    text-align: center;
}

.share-blog-link .input-container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.share-blog-link .btn {
    width: 150px;
}
/* Responsive Styling */


@media screen and (min-device-width:320px) and (max-device-width: 480px){
    .service-mega-btn button{
        width: 100%;
    }
    .service-large-btn button{
        width: 100%;
    }
    .home-certification-new .item{
        margin-bottom: 20px;
    }
    .loader-content{
        margin-top: -100px;
    }
    .preloader .loader-content .logo-icon img {
        width: 50px;
    }
    .preloader .loader-content .logo-name img {
        width: 150px;
    }
    /* Rotate twice, then move left */
@keyframes rotateAndMoveLeft {
    0% {
        transform: translateX(0px) rotate(0deg);
    }
    33% {
        transform: translateX(0px) rotate(360deg);
    }
    66% {
        transform: translateX(0px) rotate(720deg);
    }
    100% {
        transform: translateX(-100px) rotate(720deg);
    }
}

/* Fade in and slide the logo name from the right */
@keyframes fadeInAndSlideLeft {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    100% {
        opacity: 1;
        transform: translateX(20px);
    }
}
    .offcanvas{
        background: rgb(0,133,255);
        background: linear-gradient(41deg, rgba(0,133,255,1) 0%, rgba(0,0,0,1) 100%);
    }
    .large-title h1{
        font-size: 31px !important;
        line-height: 40px !important;
    }
    .technologies-section .sec-title .large-title h1 {
        font-size: 40px;
        line-height: 45px;
    }

    .sec-padding{
        padding: 50px 0px;
    }
    .navbar-toggler-icon{
        color: #fff;
        filter: brightness(0) invert(1);
    }
    .banner-section{
        height: 900px;
    }
    .banner-section .banner-title h1{
        font-size: 50px;
        line-height: 60px;
    }
    .banner-section .banner-title p{
        font-size: 13px;
        margin-top: 10px;
    }
    .banner-section .banner-logo img {
        width: 100%;
        max-width: 80%;
        height: 100%;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .banner-section .banner-title{
        padding: 50px 0px 20px 0px;
    }
    .banner-mini-shape-1 img {
        width: 55px;
    }
    .banner-mini-shape-2 {
        top: 210px;
        left: 0%;
    }
    .banner-mini-shape-2 img{
        width: 70px;
    }
    .banner-section .partners-shape{
        width: 1200px;
        bottom: -90%;
    }
    .banner-section .partners-slider{
        margin-top: 400px;
    }
    .banner-section .partners-slider .servincen img {
        width: 110px;
    }
    .AboutSection{
        padding: 50px 0px;
    }
    .AboutSection .content .shape {
        right: 70px;
        top: 25%;
        height: 50px;
        width: 50px;
    }
    .AboutSection .content p{
        font-size: 20px;
    }
    .AboutSection .right-content p {
        color: #FBFBFB;
        font-size: 13px;
        line-height: 20px;
        font-weight: 200;
    }
    .gradient-bg .gradient-bg-radial-1 {
        right: -200%;
    }
    .gradient-bg .gradient-bg-radial-2 {
        left: -200%;
    }
    .assurance_section .title-box .sec-title h1{
        font-size: 30px;
    }
    .assurance_section .shape .line1 img{
        width: 200px;
    }
    .assurance_section .shape .line2 img{
        width: 200px;
    }
    .assurance_section .title-box{
        margin-bottom: 30px;
        padding: 30px 0px;
    }
    .assurance_section .title-box .shape .line1 {
        top: 0;
    }
    .assurance_section .title-box .shape .line2{
        bottom: 0;
    }
    .assurance_section .box-content p{
        font-size: 13px;
    }
    .assurance_section .box-content{
        height: 200px;
        margin-top: 30px;
    }
    .who-we-are-section .checked-shape{
        display: none;
    }
    .who-we-are-section .left-image{
        display: flex;
        margin: auto;
    }
    .who-we-are-section .left-image img{
        width: 70%;
        height: 100%;
        margin: auto;
        padding-bottom: 20px;
    }
    .who-we-are-section .content h1{
        font-size: 40px;
        line-height: 45px;
    }
    .who-we-are-section .content p{
        font-size: 13px;
        line-height: 20px;
        font-weight: 200;
    }
    .gradient-bg-2 .gradient-bg-radial-2{
        display: none;
    }
    .gradient-bg-2 .gradient-bg-radial-1{
        right: 35%;
    }
    .gradient-bg-2 .gradient-bg-radial-3{
        right: -70%;
    }
    .our-core-services .sec-title p {
        width: 100%;
        font-size: 13px;
    }
    .our-core-services .service-box .icon {
        top: -33px;
        height: 60px;
        width: 60px;
    }
    .our-core-services .service-box .icon img {
        width: 25px;
    }
    .our-core-services .sec-title{
        margin-bottom: 80px;
    }
    .assurance_section .box-content h1{
        font-size: 20px;
    }
    .our-core-services .service-box .content h1{
        font-size: 20px;
    }
    .our-core-services .service-box .content p {
        font-size: 13px;
        padding-top: 10px;
    }
    .our-core-services .service-box{
        height: auto;
        padding: 20px;
    }
    .our-core-services .service-box .content{
        padding-top: 30px;
    }
    .our-core-services .checked-shape{
        display: none;
    }
    .approach-section .sec-title p{
        width: 100%;
        font-size: 13px;
    }
    .approach-section .sec-title{
        margin-bottom: 50px;
    }
    .approach-section .box-content .approach-box .content h1{
        font-size: 20px;
    }
    .approach-section .box-content .approach-box .content p{
        font-size: 13px;
    }
    .approach-section .box-content .approach-box{
        height: auto;
        padding: 20px;
    }
    .approach-section .box-content .approach-box-large {
        flex-wrap: wrap;
    }
    .approach-section .box-content .approach-box-large .image{
        height: 200px;
        width: 100%;
    }
    .approach-section .box-content .approach-box-large .image img{
        object-fit: cover;
    }
    #technology-sec-mobile{
        display: block;
    }
    #technology-sec-desktop{
        display: none;
    }
    .technologies-section .sec-title{
        position: initial;
        transform: none;
        width: 100%;
        margin-bottom:40px;
    }
    .technologies-section{
        margin-bottom: 0px;
        margin-top: 100px;
    }
    .technologies-section .sec-title p{
        font-size: 13px;
    }
    .technologies-section .content-one {
        position: initial;
        transform: none;
    }
    .technologies-section .content-two {
        position: initial;
        transform: none;
    }
    .technologies-section .content-three{
        position: initial;
        transform: none;
    }
    .technologies-section .content-four{
        position: initial;
        transform: none;
    }
    .technologies-section .content-five{
        position: initial;
        transform: none;
    }

    .technologies-section .box-content{
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
        padding: 15px;
    }
    .technologies-section .box-content .content h1{
        font-size: 18px;
    }
    .technologies-section .box-content .content p{
        font-size: 13px;
    }
    .cta-section{
        margin-top: 50px;
    }
    .cta-section .sec-title{
        padding: 10px;
    }
    .cta-section .sec-title{
        width: 100%;
    }
    .cta-section .sec-title p{
        font-size: 13px;
    }
    .common-btn button{
        height: 50px;
        width: 150px;
    }
    .cta-section{
        background-size: cover;
    }
    .our-core-services .service-box{
        margin-bottom: 50px;
    }
    .technologies-section .container{
        padding: 0px 25px;
    }
    .cta-section .sec-title p{
        text-align: justify;
        margin-top: 20px;
    }
}


@media screen and (min-device-width:481px) and (max-device-width: 768px){
    .loader-content{
        margin-top: -100px;
    }
    .offcanvas{
        background: rgb(0,133,255);
        background: linear-gradient(41deg, rgba(0,133,255,1) 0%, rgba(0,0,0,1) 100%);
    }
    .large-title h1{
        font-size: 50px;
        line-height: 60px;
    }
    .technologies-section .sec-title .large-title h1 {
        font-size: 50px;
        line-height: 60px;
    }


    .container{
        max-width: 90% !important;
    }
    .navbar-toggler-icon{
        color: #fff;
        filter: brightness(0) invert(1);
    }
    .banner-section{
        height: 100%;
    }
    .banner-section .banner-title h1{
        font-size: 60px;
        line-height: 70px;
    }
    .banner-section .banner-title p{
        font-size: 18px;
        margin-top: 10px;
    }
    .banner-section .banner-logo img {
        width: 100%;
        max-width: 70%;
        height: 100%;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .banner-section .banner-title{
        padding: 50px 0px 20px 0px;
    }
    .banner-mini-shape-1 img {
        width: 70px;
    }
    .banner-mini-shape-2 {
        top: 350px;
        left: 10%;
    }
    .banner-mini-shape-2 img{
        width: 80px;
    }
    .banner-section .partners-shape{
        width: 1200px;
        bottom: -825px;
    }
    .banner-section .partners-slider{
        margin-top: 500px;
    }
    .banner-section .partners-slider .servincen img {
        width: 110px;
    }
    .AboutSection{
        padding: 50px 0px;
    }
    .AboutSection .content .shape {
        right: 70px;
        top: 25%;
        height: 70px;
        width: 70px;
    }
    .AboutSection .content p{
        font-size: 25px;
    }
    .AboutSection .right-content p {
        color: #FBFBFB;
        font-size: 18px;
        line-height: 25px;
        font-weight: 200;
    }
    .gradient-bg .gradient-bg-radial-1 {
        right: -200%;
    }
    .gradient-bg .gradient-bg-radial-2 {
        left: -200%;
    }
    .assurance_section .title-box .sec-title h1{
        font-size: 35px;
    }
    .assurance_section .shape .line1 img{
        width: 200px;
    }
    .assurance_section .shape .line2 img{
        width: 200px;
    }
    .assurance_section .title-box{
        margin-bottom: 30px;
    }
    .assurance_section .title-box .shape .line1 {
        top: 0;
    }
    .assurance_section .title-box .shape .line2{
        bottom: 0;
    }
    .assurance_section .box-content p{
        font-size: 16px;
    }
    .assurance_section .box-content{
        height: 200px;
        margin-top: 30px;
    }
    .who-we-are-section .checked-shape{
        display: none;
    }
    .who-we-are-section .left-image{
        display: flex;
        margin: auto;
    }
    .who-we-are-section .left-image img{
        width: 70%;
        height: 100%;
        margin: auto;
        padding-bottom: 20px;
    }
    .who-we-are-section .content h1{
        font-size: 50px;
        line-height: 60px;
    }
    .who-we-are-section .content p{
        font-size: 18px;
        line-height: 30px;
        font-weight: 200;
    }
    .gradient-bg-2 .gradient-bg-radial-2{
        display: none;
    }
    .gradient-bg-2 .gradient-bg-radial-1{
        right: 60%;
    }
    .gradient-bg-2 .gradient-bg-radial-3{
        right: -70%;
    }
    .our-core-services .sec-title p {
        width: 100%;
        font-size: 18px;
    }
    .our-core-services .service-box .icon {
        top: -33px;
        height: 60px;
        width: 60px;
    }
    .our-core-services .service-box .icon img {
        width: 25px;
    }
    .our-core-services .sec-title{
        margin-bottom: 80px;
    }
    .assurance_section .box-content h1{
        font-size: 25px;
        margin-top: 10px;
    }
    .our-core-services .service-box .content h1{
        font-size: 25px;
    }
    .our-core-services .service-box .content p {
        font-size: 18px;
        padding-top: 10px;
    }
    .our-core-services .service-box{
        height: auto;
    }
    .our-core-services .service-box .content{
        padding-top: 30px;
    }
    .our-core-services .checked-shape{
        display: none;
    }
    .approach-section .sec-title p{
        width: 100%;
        font-size: 18px;
    }
    .approach-section .sec-title{
        margin-bottom: 50px;
    }
    .approach-section .box-content .approach-box .content h1{
        font-size: 25px;
    }
    .approach-section .box-content .approach-box .content p{
        font-size: 18px;
    }
    .approach-section .box-content .approach-box{
        height: auto;
    }
    .approach-section .box-content .approach-box-large {
        flex-wrap: wrap;
    }
    .approach-section .box-content .approach-box-large .image{
        height: 200px;
    }
    .approach-section .box-content .approach-box-large .image img{
        object-fit: cover;
    }
    #technology-sec-mobile{
        display: block;
    }
    #technology-sec-desktop{
        display: none;
    }
    .technologies-section .sec-title{
        position: initial;
        transform: none;
        width: 100%;
        margin-bottom:40px;
    }
    .technologies-section{
        margin-bottom: 0px;
        margin-top: 100px;
    }
    .technologies-section .sec-title p{
        font-size: 18px;
    }
    .technologies-section .content-one {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-two {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-three{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-four{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-five{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }

    .technologies-section .box-content{
        margin-bottom: 50px;
    }
    .technologies-section .box-content .content p{
        font-size: 18px;
    }
    .cta-section{
        margin-top: 50px;
    }
    .cta-section .sec-title{
        padding: 10px;
    }
    .cta-section .sec-title{
        width: 100%;
    }
    .cta-section .sec-title p{
        font-size: 18px;
    }
    .common-btn button{
        height: 60px;
        width: 180px;
    }
    .cta-section{
        background-size: cover;
    }
    .our-core-services .service-box{
        margin-bottom: 50px;
    }
    .technologies-section .container{
        padding: 0px 25px;
    }
    .cta-section .sec-title p{
        text-align: justify;
    }
}




@media screen and (min-device-width:769px) and (max-device-width: 1024px){
    .offcanvas{
        background: rgb(0,133,255);
        background: linear-gradient(41deg, rgba(0,133,255,1) 0%, rgba(0,0,0,1) 100%);
    }
    .large-title h1{
        font-size: 70px;
        line-height: 80px;
    }
    .technologies-section .sec-title .large-title h1 {
        font-size: 70px;
        line-height: 80px;
    }


    .container{
        max-width: 90% !important;
    }
    .navbar-toggler-icon{
        color: #fff;
        filter: brightness(0) invert(1);
    }
    .banner-section{
        height: 1070px;
    }
    .banner-section .banner-title h1{
        font-size: 80px;
        line-height: 90px;
    }
    .banner-section .banner-title p{
        font-size: 22px;
        line-height: 35px;
        margin-top: 10px;
    }
    .banner-section .banner-logo img {
        width: 100%;
        max-width: 60%;
        height: 100%;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .banner-section .banner-title{
        padding: 50px 0px 20px 0px;
    }
    .banner-mini-shape-1 img {
        width: 80px;
    }
    .banner-mini-shape-2 {
        top: 300px;
        left: 10%;
    }
    .banner-mini-shape-2 img{
        width: 120px;
    }
    .banner-section .partners-shape{
        width: 2000px;
        bottom: -825px;
    }
    .banner-section .partners-slider{
        margin-top: 500px;
    }
    .banner-section .partners-slider img{
        width: 100px;
    }
    .banner-section .partners-slider .servincen img {
        width: 150px;
    }
    .AboutSection{
        padding:100px 0px 50px 0px;
    }
    .AboutSection .content .shape {
        right: 70px;
        top: 25%;
        height: 100px;
        width: 100px;
    }
    .AboutSection .content p{
        font-size: 30px;
    }
    .AboutSection .right-content p {
        color: #FBFBFB;
        font-size: 25px;
        line-height: 40px;
        font-weight: 200;
    }
    .gradient-bg .gradient-bg-radial-1 {
        right: -200%;
    }
    .gradient-bg .gradient-bg-radial-2 {
        left: -200%;
    }
    .assurance_section .box-content h1{
        font-size: 30px;
        margin-top: 10px;
    }
    .assurance_section .title-box .sec-title h1{
        font-size: 45px;
    }
    .assurance_section .shape .line1 img{
        width: 300px;
    }
    .assurance_section .shape .line2 img{
        width: 300px;
    }
    .assurance_section .title-box{
        margin-bottom: 30px;
    }
    .assurance_section .title-box .shape .line1 {
        top: 0;
    }
    .assurance_section .title-box .shape .line2{
        bottom: 0;
    }
    .center-subtitle h6{
        font-size: 20px;
    }
    .assurance_section .box-content p{
        font-size: 20px;
    }
    .assurance_section .box-content{
        height: auto;
        margin-top: 30px;
    }
    .who-we-are-section .checked-shape{
        display: none;
    }
    .who-we-are-section .left-image{
        display: flex;
        margin: auto;
    }
    .who-we-are-section .left-image img{
        width: 60%;
        height: 100%;
        margin: auto;
        padding-bottom: 20px;
    }
    .who-we-are-section .content h1{
        font-size: 70px;
        line-height: 80px;
    }
    .who-we-are-section .content p{
        font-size: 25px;
        line-height: 40px;
        font-weight: 200;
    }
    .left-subtitle h6{
        font-size: 20px;
    }
    .gradient-bg-2 .gradient-bg-radial-2{
        display: none;
    }
    .gradient-bg-2 .gradient-bg-radial-1{
        right: 60%;
    }
    .gradient-bg-2 .gradient-bg-radial-3{
        right: -70%;
    }
    .our-core-services .sec-title p {
        width: 100%;
        font-size: 18px;
    }
    .our-core-services .service-box .icon {
        top: -33px;
        height: 70px;
        width: 70px;
    }
    .our-core-services .service-box .icon img {
        width: 25px;
    }
    .our-core-services .sec-title{
        margin-bottom: 80px;
    }
    .our-core-services .service-box .content h1{
        font-size: 35px;
    }
    .our-core-services .service-box .content p {
        font-size: 22px;
        padding-top: 10px;
    }
    .our-core-services .service-box{
        height: auto;
    }
    .our-core-services .service-box .content{
        padding-top: 30px;
    }
    .our-core-services .checked-shape{
        display: none;
    }
    .approach-section .sec-title p{
        width: 100%;
        font-size: 22px;
    }
    .center-subtitle h6{
        font-size: 20px;
    }
    .approach-section .box-content .approach-box .icon {
        height: 70px;
        width: 70px;
    }
    .approach-section .box-content .approach-box .icon img{
        width: 50px;
    }
    .approach-section .sec-title{
        margin-bottom: 50px;
    }
    .approach-section .box-content .approach-box .content h1{
        font-size: 35px;
    }
    .approach-section .box-content .approach-box .content p{
        font-size: 22px;
    }
    .approach-section .box-content .approach-box{
        height: auto;
    }
    .approach-section .box-content .approach-box-large {
        flex-wrap: wrap;
    }
    .approach-section .box-content .approach-box-large .image{
        height: 300px;
    }
    .approach-section .box-content .approach-box-large .image img{
        object-fit: cover;
    }
    #technology-sec-mobile{
        display: block;
    }
    #technology-sec-desktop{
        display: none;
    }
    .technologies-section .sec-title{
        position: initial;
        transform: none;
        width: 100%;
        margin-bottom:40px;
    }
    .technologies-section{
        margin-bottom: 0px;
        margin-top: 100px;
    }
    .technologies-section .sec-title p{
        font-size: 22px;
    }
    .technologies-section .content-one {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-two {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-three{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-four{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }
    .technologies-section .content-five{
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;
    }

    .technologies-section .box-content{
        margin-bottom: 50px;
    }
    .technologies-section .box-content .content p{
        font-size: 22px;
    }
    .technologies-section .box-content .content h1{
        font-size: 30px;
    }
    .technologies-section .box-content .icon img{
        width: 50px;
    }
    .cta-section{
        margin-top: 50px;
    }
    .cta-section .sec-title{
        padding: 10px;
    }
    .cta-section .sec-title{
        width: 100%;
    }
    .cta-section .sec-title p{
        font-size: 22px;
    }
    .common-btn button{
        height: 70px;
        width: 200px;
        font-size: 20px;
    }
    .cta-section{
        background-size: cover;
    }
    .our-core-services .service-box{
        margin-bottom: 50px;
    }
    .technologies-section .container{
        padding: 0px 25px;
    }
    .cta-section .sec-title p{
        text-align: justify;
    }
}

@media screen and (min-device-width:1025px) and (max-device-width: 1400px){


    /* contact page style */
    .contact-breadcrumb .downarrow{
        top: 50% !important;
    }
    .approach-box-large .content{
        width: 60%;
    }
    .approach-box-large .image{
        width: 40% !important;
    }
    .approach-box-large .image img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .banner-section{
        height: 790px;
    }
    .banner-section .banner-title h1 {
        font-size: 80px;
        line-height: 90px;
    }
    .banner-section .banner-logo img{
        width: 500px;
        height: 500px;
    }
    .banner-section .banner-title p {
        margin-top: 30px;
        font-size: 16px;
    }
    .banner-mini-shape-2{
        top: 460px;
    }
    .banner-mini-shape-2 img {
        width: 120px;
    }
    .banner-section .partners-slider{
        margin-top: 85px;
    }

    .AboutSection .content .large-title h1{
        font-size: 80px;
        line-height: 80px;
    }
    .AboutSection .content .shape {
        height: 70px;
        width: 70px;
        right: 25px;
    }
    .AboutSection .right-content p{
        font-size: 13px;
    }
    .who-we-are-section .left-image .gradient-shape {
        left: 35%;
    }
    .gradient-bg-2 .gradient-bg-radial-2 {
        width: 500px;
    }
    .gradient-bg-2 .gradient-bg-radial-1{
        width: 500px;
    }
    .gradient-bg-2 .gradient-bg-radial-3 {
        opacity: 0.5;
    }
    .our-core-services .service-box{
        height: 450px;
    }
    .our-core-services .checked-shape {
        bottom: 15%;
    }
    .approach-section .box-content .approach-box{
        height: 480px;
    }
    .approach-section .box-content .approach-box-large .image img{
        object-fit: cover;
    }
    .technologies-section .sec-title .large-title h1{
        font-size: 65px;
        line-height: 70px;
    }
    .technologies-section .content-five{
        right: 1%;
    }
    .cta-section .sec-title {
        padding: 20px 0px 30px 0px;
        margin: auto;
        width: 90%;
        margin-bottom: 80px;
        text-align: center;
    }
    /* assurance */
    .gradient-bg .gradient-bg-radial-2{
       left: -60%;
       height: 1200px;
    }
    .gradient-bg .gradient-bg-radial-1{
        height: 1200px;
        right: -70%;
    }
    .assurance_section .box-content{
        height: 250px;
    }
    .assurance_section .box-content h1{
        font-size: 25px;
    }
    .assurance_section .box-content p{
        font-size: 13px;
    }
    .who-we-are-section .content h1{
        font-size: 76px;
        line-height: 80px;
    }
    .technologies-section .circle3{
        width: 1100px;
        height: 1100px;
    }
    .technologies-section .circle2{
        height: 900px;
        width: 900px;
    }
    .technologies-section .circle1{
        height: 700px;
        width: 700px;
    }
}



/* 
404 page styles */

/* 

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@100;300&display=swap');

:root {
  --button: hsl(44, 0%, 70%);
  --button-color: hsl(0, 0%, 4%);
  --shadow: hsl(0, 0%, 0%);
  --bg: hsl(53, 0%, 45%);
  --header: hsl(53, 0%, 48%);
  --color: hsl(0, 0%, 98%);
  --lit-header: hsl(53, 0%, 90%);
  --speed: 2s;
}

* {
  box-sizing: border-box;
  transform-style: preserve-3d;
}

@property --swing-x {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}

@property --swing-y {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}

.ErrorPage {
  min-height: 100vh;
  display: flex;
  font-family: 'Roboto', sans-serif;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--color);
  perspective: 1200px;
}

.ErrorPage a {
  text-transform: uppercase;
  text-decoration: none;
  background: var(--button);
  color: var(--button-color);
  padding: 1rem 4rem;
  border-radius: 4rem;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
}

.ErrorPage p {
  font-weight: 100;
}

.ErrorPage h1 {
  animation: swing var(--speed) infinite alternate ease-in-out;
  font-size: clamp(5rem, 40vmin, 20rem);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  margin-bottom: 1rem;
  letter-spacing: 1rem;
  transform: translate3d(0, 0, 0vmin);
  --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
  background: radial-gradient(var(--lit-header), var(--header) 45%) var(--x) 100% / 200% 200%;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
}

.ErrorPage h1:after {
  animation: swing var(--speed) infinite alternate ease-in-out;
  content: "404";
  position: absolute;
  top: 0;
  left: 0;
  color: var(--shadow);
  filter: blur(1.5vmin);
  transform: scale(1.05) translate3d(0, 12%, -10vmin) 
    translate(calc((var(--swing-x, 0) * 0.05) * 1%), calc((var(--swing-y) * 0.05) * 1%));
}

.ErrorPage .cloak {
  animation: swing var(--speed) infinite alternate-reverse ease-in-out;
  height: 100%;
  width: 100%;
  transform-origin: 50% 30%;
  transform: rotate(calc(var(--swing-x) * -0.25deg));
  background: radial-gradient(40% 40% at 50% 42%, transparent, black 35%);
}

.ErrorPage .cloak__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.ErrorPage .cloak__container {
  height: 250vmax;
  width: 250vmax;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ErrorPage .info {
  text-align: center;
  line-height: 1.5;
  max-width: clamp(16rem, 90vmin, 25rem);
}
.ErrorPage .info h2{
    margin-bottom: 30px;
}

.ErrorPage .info > p {
  margin-bottom: 3rem;
}

@keyframes swing {
  0% {
    --swing-x: -100;
    --swing-y: -100;
  }
  50% {
    --swing-y: 0;
  }
  100% {
    --swing-y: -100;
    --swing-x: 100;
  }
} */
