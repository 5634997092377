/* fonts */
@import url('https://fonts.cdnfonts.com/css/graphik-trial');
@import url('https://fonts.cdnfonts.com/css/inter');

a{
  cursor: pointer;
  font-family: var(--graphik-font);
}
.prophecy{
  background-color: #000;
}
html{
  background-color: #000;
}
h1{
  font-family: var(--graphik-font);
}
h2{
  font-family: var(--graphik-font);
}
h3{
  font-family: var(--graphik-font);
}
h4{
  font-family: var(--graphik-font);
}
h5{
  font-family: var(--graphik-font);
}
h6{
  font-family:var(--graphik-font);
}
p{
  text-align: justify;
  font-family: var(--inter-font);
  color: var(--light-color) !important;
}


.dashboard-main{
  /* background: rgb(2,126,240);
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%); */
  background-color: #fff;
  margin: 0px;
}
.dashboard-main .col-lg-3{
  padding: 0px
}
.dashboard-left-contents{
  width: 85%;
  height: 100vh;
  background-color: #000;
  margin: 0px;
  padding: 50px;
  position: sticky;
  top: 0; /* Adjust the value to account for any header height */
    height: 100vh; /* Full height for the sidebar */
    overflow-y: auto; /* Adds scrolling if the content exceeds the viewport */
}
.dashboard-left-contents .logo img{
  filter: brightness(0) invert(1);
  width: 200px;
}
.dashboard-sidebar{
  height: 90%;
}
.dashboard-sidebar .bottom-btn{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.dashboard-sidebar .menus{
  margin-top: 50px;
}
.dashboard-sidebar ul li{
  padding: 10px 0px;
  border-bottom: 1px solid #027df02e;
}
.dashboard-sidebar ul li button{
  padding: 10px 0px;
  width: 100%;
  border-bottom: 1px solid rgb(70, 70, 70);
  background-color: #000;
  border: none;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  padding-left: 20px;
  font-size: 18px;
  transition: all 0.5s ease;
}
.activeTab{
  background: rgb(2,126,240);
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
  color: #000 !important;
  font-weight: 600;
}

.dashboard-sidebar ul li button:hover{
  background: rgb(2,126,240);
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
  color: #000 !important;
  font-weight: 600;
}

.dashboard-sidebar li .active a{
  padding: 15px 0px;
  display: flex;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  background: rgb(2,126,240);
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
  color: #000;
  font-weight: 600;

}
.dashboard-sidebar ul li:hover a{
  padding-left: 30px;
}
.dashboard-sidebar ul{
  list-style-type: none;
  padding-left: 0px;
}
.dashboard-sidebar .footer button{
  background: rgb(2,126,240);
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
  color: #000;
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px;
  font-weight: 600;
  transition: all 1s ease;
}
.dashboard-sidebar .footer button:hover{
  border: 1px solid #027EF0;
    box-shadow: 0 0 100px #027df034, 0 0 60px #027df066, 0 0 40px #027EF0;
}
/* dashboard css */
.dashboard-page {
  padding: 40px;
}
.dashboard-page .title h1{
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  
}
.dashboard-page .logo-dashboard img{
  width: 600px;
}

.dashboard-forms .tbody{
  overflow-x: scroll;
}
/* add post style */
.dashboard-forms input:valid {
  color: #000;
}
.dashboard-forms{
  padding: 40px 0px;
}
.dashboard-forms .head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-forms .head .btns a{
  margin-left: 10px;
}
.dashboard-forms  .blog-page-switch{
  background-color: #000;
  padding: 15px 20px;
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
}
.dashboard-forms h2{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.dashboard-forms label{
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}
.dashboard-forms input{
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;width: 100%;
  border: rgb(229, 228, 228);
  border-radius: 10px;
  padding: 10px 20px;
}
.dashboard-forms select{
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;width: 100%;
  border: rgb(229, 228, 228);
  border-radius: 10px;
  padding: 10px 20px;
}
.dashboard-forms textarea{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  border: rgb(229, 228, 228);
  border-radius: 10px;
  padding: 10px 20px;
}
.dashboard-forms .description-section .description{
  background-color: #ffff;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 30px;
}
.description{
  background-color: #ffff;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 30px;
}
#editorjs .codex-editor__redactor{
  padding-bottom: 20px !important;
}
.dashboard-forms .description-section h2{
  font-size: 22px;
  font-weight: 600;
  margin-top: 30px;
}
.dashboard-forms .description-section p{
  font-size: 15px !important;
  color: #000 !important;
}

.dashboard-forms .right-contents  h2{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.dashboard-forms .right-contents {
  padding: 20px;
}
.dashboard-forms .right-contents  label{
  padding-top: 10px;
}
.dashboard-forms button{
  background-color: #000;
  padding: 15px 20px;
  width: 100%;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  margin-top: 50px;
}
.ql-toolbar  button{
  margin-top: 5px;
}
.manage-post-dasgboard {
  padding: 10px !important;
}


/* login page styles */

.login-page-main{
  background-image: url("../Resources/blogimage.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
}
.login-page-main::before{
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: #0000009b;
}
.login-page input{
  width: 100%;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  margin-top: 30px;
}
.login-page button{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  background-color: #000;
  width: 100%;
  color: #fff;
  margin-top: 30px;
}
.login-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 50px;
  z-index: 9999;
  position: relative;
}
.login-page-main .heading{
  font-family: var(--inter-font);
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  color: #000;
}
.login-page-main .logo{
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
}
.login-page-main .logo img{
  width: 200px;
}
.manage-post-dasgboard .title{
  padding: 40px 0px;
}
/* .manage-post-dasgboard .table-contents{
  padding: 40px ;
} */

.manage-post-dasgboard .table-headings{
  padding: 15px 0px !important;
}
.manage-post-dasgboard .table-headings th{
  text-align: center;
  padding: 10px 10px;
}
.manage-post-dasgboard-two{
  border-radius: 30px !important;
}
.data-content-tables .action-btns{
  display: flex;
  gap: 10px;
  justify-content: center;
}

.data-content-tables th{
  display: flex;
  align-items: center;
  padding: 10px 50px;
  
}
.data-content-tables td{
  padding: 5px 10px;
  font-size: 15px;
  border-bottom: 1px solid #ededed;
}
.data-content-tables .delete-btn button{
  background-color: #ededed;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0px;
  margin: auto;
}
.data-content-tables .delete-btn svg{
  color: red;
  font-size: 25px;
}
.data-content-tables .edit-btn a{
  background-color: #ededed;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0px;
  color: green;
  font-size: 25px;
}
.manage-post-dasgboard .edit-items{
  justify-content: center;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
  text-decoration: none;
}
.manage-post-dasgboard button{
  margin-top: 0px;
}

.dashboard-forms textarea{
  color: #000;
}
.dashboard-right-contents table{
  width: 100%;
  text-align: center;
}
.dashboard-right-contents{
  padding-right: 40px;
}

.add-career-form-sec .select option{
  width: 100%;
}