


.contact-breadcrumb .breadcrumb-title h6{
    font-size: 35px;
    font-weight: 600;
    color: #fff;
}
.contact-breadcrumb .breadcrumb-title{
    padding: 50px 0px ;
    position: relative;
    z-index: 99;
}
.contact-breadcrumb .breadcrumb-title{
    font-size: 18px;
    max-width: 70%;
}

.contact-breadcrumb .downarrow {
    position: absolute;
    bottom: -5%;
    right: 50%;
    z-index: 999;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: rgb(0,98,188);
    background: linear-gradient(180deg, rgba(0,98,188,1) 0%, rgba(203,224,255,1) 100%);
    border: 2px solid #fff;
}
.contact-breadcrumb .downarrow  img{
    max-width: 30%;
}
.contact-breadcrumb .shape1{
    z-index: 2;
    position: absolute;
    top: 25%;
}
.contact-breadcrumb .shape1 img{
    width: 100%;
}
.contact-breadcrumb .shape2{
    z-index: 1;
    position: absolute;
    top: 25%;
}
.contact-breadcrumb .shape2 img{
    width: 100%;
}


.contact-form-section{
    padding: 400px 0px 0px 0px;
}
.contact-form-section .checked-shape{
    position: absolute;
    left: -36%;
    top: 45%;
    z-index: 1;
}
.contact-form-section .checked-shape img{
    width: 500px;
    height: 400px;
}
.contact-form-section .sec-title{
    text-align: center;
}
.contact-form-section .sec-title h1{
        padding-bottom: 10px;
        margin: auto;
        position: relative;
        color: #81c2ff;
    }
    .contact-form-section .sec-title  h6{
        font-family: 'Graphik Trial', sans-serif;
        font-size: 35px;
        font-weight: 600;
        color: #fff;
    }
    .contact-form-section .contact-form{
        margin: auto;
        max-width: 1200px;
        position: relative;
        margin-top: 50px;
        padding: 20px;
        border-radius: 20px;
        border: 1px solid #027EF0;
        background: rgb(0,47,142);
        background: linear-gradient(36deg, rgba(0, 47, 142, 0.1) 0%, rgba(0,0,0,0.5) 67%, rgba(0,47,142,0.5) 100%);
        transition: all 1s ease;
    }
    .contact-form-section .contact-form:hover{
        box-shadow: 0 0 100px #027df034, 0 0 60px #027df066, 0 0 40px #027EF0;
    }
    .contact-form-section .contact-form h1{
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 30px;
        color: #fff;
        margin-bottom: 30px;
    }
    .contact-form-section .contact-form label{
        width: 100%;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
        font-size: 18px;
        color: #fff;
        margin: 10px;
    }
    .contact-form-section .contact-form input{
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        background-color: #000;
        box-shadow: none;
        border: 1px solid #3E3E3E;
        margin-top: 20px;
    }
    .contact-form-section .contact-form textarea{
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        background-color: #000;
        box-shadow: none;
        border: 1px solid #3E3E3E;
        margin-top: 20px;
        height: 200px;
    }
    .checkbox-form {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        color: #5e5e5e  !important;
      }
      
      .custom-checkbox {
        opacity: 0;
        position: absolute;
      }
      
      .checkmark {
        height: 20px;
        width: 20px;
        background-color: #000;
        border: 2px solid #3E3E3E; 
        margin-right: 10px;
        display: inline-block;
        border-radius: 4px; 
        position: relative;

      }
      
      .checkbox-form input:checked + .checkmark {
        background-color: #3E3E3E; 
      }
      
      .checkbox-form input:checked + .checkmark::after {
        content: "";
        position: absolute;
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
      
      .form-submit-btn{
        display: flex;
        justify-content: center;
      }
      .form-submit-btn button{
        margin: 30px 0px 30px 0px;
        background: rgb(2,126,240);
        background: linear-gradient(270deg, rgba(255,255,255,1) 50%,rgba(2,126,240,1) 100%);
        height: 60px;
        width: 600px;
        border-radius: 10px;
        border: none;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 17px;
    }
    .contact-gradient-bg .gradient-bg-radial-2{
        z-index: 2;
    }
    .contact-gradient-bg .gradient-bg-radial-1{
        z-index: 2;
    }
    .contact-gradient-bg{
        overflow-x: hidden;
    }

    .gradient-bg{
        position: relative;
        overflow: hidden;
     }
     
     .gradient-bg-radial-1{
         position: absolute;
         top: 20%;
         right: -45%;
         height: 1200px;
         width: 800px;
         border-radius: 500px;
         filter: blur(100px);
         background: rgb(0,64,255);
         background: radial-gradient(circle, rgba(0,64,255,1) 0%, rgba(0,64,255,0) 100%);
     }
     .gradient-bg-radial-2{
         position: absolute;
         top: 20%;
         left: -42%;
         height: 1200px;
         width: 700px;
         border-radius: 500px;
         filter: blur(100px);
         background: rgb(0,64,255);
         background: radial-gradient(circle, rgba(0,64,255,1) 0%, rgba(0,64,255,0) 100%);
     }

    


    /* opportunity styles */

    .opportunity-section {
        padding: 200px 0px 100px 0px;
    }
    .opportunity-section .sec-title{
        text-align: center;
        justify-content: center;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .opportunity-section .sec-title h1{
            padding-bottom: 10px;
            margin: auto;
            position: relative;
            color: #81c2ff;
}
.opportunity-section .sec-title  h6{
            font-family: 'Graphik Trial', sans-serif;
            font-size: 35px;
            font-weight: 600;
            background: rgb(255,255,255);
            background: linear-gradient(80deg, rgba(255,255,255,1) 0%, rgba(118,117,133,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
}
.opportunity-section .sec-title p{
    font-family: 'Inter', sans-serif;
    padding-top: 20px;
    color: #FBFBFB;
    width: 70%;
    text-align: center;
    font-weight: 200;
}



/* contact-location styles */

.contact-location{
    position: relative;
    padding: 130px 0px;
    background-image: url("../Resources//contact-map-bg-2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.contact-location .sec-title h1{
    padding-bottom: 10px;
        margin: auto;
        position: relative;
        color: #81c2ff;
}
.contact-location .sec-title  h6{
        font-family: 'Graphik Trial', sans-serif;
        font-size: 35px;
        font-weight: 600;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 45%, rgba(0,133,255,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
}
.contact-location .sec-title{
    text-align: center;
}
.contact-location .checked-shape{
    position: absolute;
    top: -50%;
    right: -7%;
}
.contact-location .checked-shape img{
    height: 400px;
    width: 500px;
}

/* branch address styles */

.branch-address .second-location{
    margin: auto;
    margin-top: 20px;
    justify-content: center;
}
.branch-address .branch-item{
    max-width: 70%;
    margin: auto;
    text-align: center;
}
.branch-address h6{
    font-family: 'Graphik Trial', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
}
.branch-address p{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 14px;
    color: #D9D9D9;
}


/* contact cta styles */

.contact-cta{
    margin: 100px 0px;
    padding: 100px 0px;
    background-image: url("../Resources//contact-cta-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
}
.contact-cta .sec-title{
    text-align: center;
}
.contact-cta .sec-title h1{
        padding-bottom: 10px;
        margin: auto;
        width: 100%;
        color: #001d38;
        position: relative;
}
.contact-cta .sec-title  h6{
    width: 70%;
    display: flex;
    margin: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #000;
}



/* responsive  */

@media screen and (min-device-width:320px) and (max-device-width: 480px){
    .service-points-stick .sticky-content{
        height: 100% !important;
        margin-bottom: 50px;
    }
    .contact-breadcrumb .breadcrumb-title{
        width: 100%;
        max-width: 95%;
    }
    .contact-breadcrumb .breadcrumb-title h1{
        font-size: 40px;
    }
    .contact-breadcrumb .breadcrumb-title h6{
        font-size: 20px;
    }
    .contact-breadcrumb .breadcrumb-title p{
        font-size: 10px;
        color: var(--light-color);
    }
    .contact-breadcrumb .shape2{
        display: none;
    }
    .contact-breadcrumb .shape1{
        display: none;
    }
    .contact-form-section {
        padding-top: 50px;
    }
    .contact-breadcrumb .downarrow{
        display: none;
    }
    .contact-form-section .sec-title h6{
        font-size: 20px;
    }
    .contact-form-section .contact-form h1{
        font-size: 20px;
    }
    .contact-form-section .contact-form label{
        font-size: 15px;
        margin: 0px;
        padding-top: 20px;
    }
    .contact-form-section .checked-shape {
        left: -50%;
        top: 100%;
    }
    .contact-form-section .checked-shape img {
        width: 300px;
        height: 250px;
    }
    .opportunity-section{
        padding: 100px 0px;
    }
    .opportunity-section .large-title {
        z-index: 9999;
    }
    .opportunity-section .large-title h6{
        font-size: 20px;
    }
    .opportunity-section .sec-title p{
        color: var(--light-color);
        width: 100%;
        font-size: 13px;
    }
    .contact-location .checked-shape{
        display: none;
    }
    .contact-location{
        padding: 50px 0px;
    }
    .contact-location .sec-title h6{
        font-size: 20px;
    }
    .common-btn button {
        height: 50px;
        width: 150px;
        font-size: 15px;
    }
    .contact-cta {
        padding: 50px 0px;
    }
    .contact-cta .sec-title h6{
        width: 100%;
        font-size: 13px;
    }
    .branch-address .branch-item{
        width: 100%;
        margin-bottom: 30px;
    }
    .branch-address p{
        font-size: 13px;
    }
    .branch-address h6 {
        font-size: 18px;
    }
}


@media screen and (min-device-width:481px) and (max-device-width: 768px){

    .contact-breadcrumb .breadcrumb-title{
        width: 100%;
        max-width: 95%;
    }
    .contact-breadcrumb .breadcrumb-title h1{
        font-size: 50px;
    }
    .contact-breadcrumb .breadcrumb-title h6{
        font-size: 25px;
    }
    .contact-breadcrumb .breadcrumb-title p{
        font-size: 13px;
        color: var(--light-color);
    }
    .contact-breadcrumb .shape2{
        display: none;
    }
    .contact-breadcrumb .shape1{
        display: none;
    }
    .contact-form-section {
        padding-top: 50px;
    }
    .contact-breadcrumb .downarrow{
        display: none;
    }
    .contact-form-section .sec-title h6{
        font-size: 20px;
    }
    .contact-form-section .contact-form h1{
        font-size: 25px;
    }
    .contact-form-section .contact-form label{
        font-size: 18px;
        margin: 0px;
        padding-top: 20px;
    }
    .contact-form-section .checked-shape {
        left: -50%;
        top: 100%;
    }
    .contact-form-section .checked-shape img {
        width: 300px;
        height: 250px;
    }
    .opportunity-section{
        padding: 100px 0px;
    }
    .opportunity-section .large-title {
        z-index: 9999;
    }
    .opportunity-section .large-title h6{
        font-size: 25px;
    }
    .opportunity-section .sec-title p{
        width: 100%;
        font-size: 13px;
        color: var(--light-color);
    }
    .contact-location .checked-shape{
        display: none;
    }
    .contact-location{
        padding: 50px 0px;
    }
    .contact-location .sec-title h6{
        font-size: 25px;
    }
    .common-btn button {
        height: 55px;
        width: 160px;
        font-size: 17px;
    }
    .contact-cta {
        padding: 50px 0px;
    }
    .contact-cta .sec-title h6{
        width: 100%;
        font-size: 13px;
    }
    .branch-address .branch-item{
        width: 100%;
        margin-bottom: 30px;
    }
    .branch-address p{
        font-size: 18px;
    }
    .branch-address h6 {
        font-size: 22px;
    }
}

@media screen and (min-device-width:769px) and (max-device-width: 1024px){
    

    .contact-breadcrumb .breadcrumb-title{
        width: 100%;
        max-width: 95%;
    }
    .contact-breadcrumb .breadcrumb-title h1{
        font-size: 80px;
    }
    .contact-breadcrumb .breadcrumb-title h6{
        font-size: 30px;
    }
    .contact-breadcrumb .breadcrumb-title p{
        font-size: 18px;
        color: var(--light-color);
    }
    .contact-breadcrumb .shape2{
        display: none;
    }
    .contact-breadcrumb .shape1{
        display: none;
    }
    .contact-form-section {
        padding-top: 50px;
    }
    .contact-breadcrumb .downarrow{
        display: none;
    }
    .contact-form-section .sec-title h6{
        font-size: 30px;
    }
    .contact-form-section .contact-form h1{
        font-size: 28px;
    }
    .contact-form-section .contact-form label{
        font-size: 20px;
        margin: 0px;
        padding-top: 20px;
    }
    .contact-form-section .checked-shape {
        left: -50%;
        top: 100%;
    }
    .contact-form-section .checked-shape img {
        width: 300px;
        height: 250px;
    }
    .opportunity-section{
        padding: 100px 0px;
    }
    .opportunity-section .large-title {
        z-index: 9999;
    }
    .opportunity-section .large-title h6{
        font-size: 30px;
    }
    .opportunity-section .sec-title p{
        width: 100%;
        font-size: 18px;
        color: var(--light-color);
    }
    .contact-location .checked-shape{
        display: none;
    }
    .contact-location{
        padding: 50px 0px;
    }
    .contact-location .sec-title h6{
        font-size: 25px;
    }
    .common-btn button {
        height: 60px;
        width: 170px;
        font-size: 18px;
    }
    .contact-cta {
        padding: 50px 0px;
    }
    .contact-cta .sec-title h6{
        width: 100%;
        font-size: 18px;
    }
    .branch-address .branch-item{
        width: 100%;
        margin-bottom: 30px;
    }
    .branch-address p{
        font-size: 18px;
    }
    .branch-address h6 {
        font-size: 25px;
    }
}


@media screen and (min-device-width:1024px) and (max-device-width: 1400px){
    .contact-breadcrumb .downarrow{
        bottom: -45%;
    }
}